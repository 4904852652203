import { ErrorBoundary } from '@sentry/react';
import { useRouter } from 'next/navigation';

import AppFooter from '../atoms/AppFooter';
import AppSection from '../atoms/AppSection';
import AppHeader from '../organisms/AppHeader';
import { Button } from '../ui/button';

const CustomErrorBoundary = ({ children }) => {
  return <ErrorBoundary fallback={FallbackRender}>{children}</ErrorBoundary>;
};

const FallbackRender = ({ error, resetError }) => {
  const router = useRouter();
  return (
    <>
      <AppHeader hideSearch={true} landingPage={true} />

      <div
        style={{
          minHeight: 'calc(100vh - 160px)',
        }}
      >
        <main className="min-h-screen/2 mt-24">
          <AppSection title="Error" className="grid place-items-center">
            <div>
              <h1>An error occurred!</h1>
              <p>Something went wrong and we have been notified.</p>
              <p>{error?.message ?? ''}</p>
              <div className="mt-4 flex gap-3">
                <Button
                  type="button"
                  variant="outline"
                  onClick={() => {
                    resetError();
                    router.push('/');
                  }}
                >
                  Go back to home page
                </Button>
                <Button type="button" onClick={() => resetError()}>
                  Reload the page
                </Button>
              </div>
            </div>
          </AppSection>
        </main>
      </div>
      <div style={{ position: 'fixed', bottom: 0, width: '100%' }}>
        <AppFooter />
      </div>
    </>
  );
};

export default CustomErrorBoundary;
