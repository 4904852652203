'use client';

import { useState } from 'react';

import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuRadioItem,
  DropdownMenuRadioGroup,
} from '@/components/ui/dropdown-menu';

interface Props {
  dark?: boolean;
}
const CurrencySelection = ({ dark = false }: Props) => {
  const [currency, setCurrency] = useState<string>('Euro');
  const Currencies = ['Euro', 'Dollar', 'Livre'];
  enum Currency {
    Euro = 'EUR (€)',
    Dollar = 'USD ($)',
    Livre = 'GBP (£)',
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild className="rounded-md">
        <Button
          variant="ghost"
          className={`${dark ? 'bg-primary ' : 'bg-white'} inline-flex h-9 items-center justify-center rounded-full bg-opacity-40 py-2 pl-3.5 pr-3 text-sm font-medium leading-5  backdrop-blur-sm focus-within:border-transparent focus-within:outline focus-within:outline-2 hover:bg-gray-100 active:bg-gray-50 active:text-gray-800`}
        >
          <span className="block">{Currency[currency]}</span>

          <svg className="ml-1 -mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-40">
        <DropdownMenuLabel>Currency</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuRadioGroup value={currency} onValueChange={setCurrency}>
          {Currencies.map((disp_currency) => (
            <DropdownMenuRadioItem
              className="relative inline-flex w-full cursor-pointer items-center justify-start gap-2 px-4 py-3 text-left text-sm leading-5 text-gray-700 hover:bg-gray-100 focus-visible:outline-1 focus-visible:outline-blue-500 "
              value={disp_currency}
              key={disp_currency}
            >
              <p
                className={`text-sm ${
                  disp_currency == currency ? 'font-bold' : 'font-medium'
                } leading-5`}
              >
                {Currency[disp_currency]}
              </p>
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default CurrencySelection;
