'use client';

import { useEffect, useRef } from 'react';

import ProgressBar from '@badrap/bar-of-progress';
import Router from 'next/router';
import { AbstractIntlMessages, IntlErrorCode, NextIntlClientProvider } from 'next-intl';
import { hotjar } from 'react-hotjar';

import CustomErrorBoundary from '@/components/shared/CustomErrorBoundary';
import { RedirectUrlProvider } from 'context/redirectUrlProvider';

const progressBar = new ProgressBar({
  size: 4,
  color: '#FF385C',
  className: 'z-50',
  delay: 100,
});

Router.events.on('routeChangeStart', progressBar.start);
Router.events.on('routeChangeComplete', progressBar.finish);
Router.events.on('routeChangeError', progressBar.finish);

const HOTJAR_ID = 3924855;
const HOTJAR_SNIPPET_VERSION = 6;

export default function ClientLayout({
  children,
  locale,
  messages,
}: {
  children: React.ReactNode;
  locale: string;
  messages: AbstractIntlMessages;
}) {
  useEffect(() => {
    hotjar.initialize({ id: HOTJAR_ID, sv: HOTJAR_SNIPPET_VERSION });
  }, []);

  // Note that if we remove this feature we could move it to the server side
  const alreadyReportedMissingTranslation = useRef(new Set());

  // Avoid to see errors when pageProps.messages is not initialized already
  // Note that it may hide a bugs when getServerSideProps is not loading properly messages
  alreadyReportedMissingTranslation.current.add(
    'No messages were configured on the provider.'
  );

  function onError(error) {
    if (error.code === IntlErrorCode.MISSING_MESSAGE) {
      // Missing translations are expected and should only log a warning once
      if (!alreadyReportedMissingTranslation.current.has(error.originalMessage)) {
        console.warn(`Translations - ${error.originalMessage}`);

        alreadyReportedMissingTranslation.current.add(error.originalMessage);
      }
    } else {
      // Other errors indicate a bug in the app and should be reported
      throw error;
    }
  }

  return (
    <NextIntlClientProvider
      locale={locale}
      messages={messages}
      timeZone="Europe/Paris"
      now={new Date()}
      onError={onError}
    >
      <RedirectUrlProvider>
        <CustomErrorBoundary>{children}</CustomErrorBoundary>
      </RedirectUrlProvider>
    </NextIntlClientProvider>
  );
}
