import { PropsWithChildren } from 'react';

interface IAppSectionProps extends PropsWithChildren<any> {
  title: string;
  className?: string;
  id?: string;
}

const AppSection = ({ title, className, children, id }: IAppSectionProps) => {
  return (
    <section className="my-10" id={id}>
      <div className="container max-w-6xl">
        <a id={title}>
          <h2 className="mb-4 text-2xl font-bold md:mb-4 md:text-3xl lg:mb-8 lg:text-4xl">
            {title}
          </h2>
        </a>
        <div className={className}>{children}</div>
      </div>
    </section>
  );
};

export default AppSection;
